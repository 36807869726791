import React from 'react'
import './Header.css'
import { Link, NavLink } from "react-router-dom";
import {Typography} from "@mui/material";

class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            anchorEl: null,
            open: false
        }
    }

    handleClick = event => {
        this.setState({ open: true, anchorEl: event.currentTarget });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };


    render () {
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="mr-sm-2">
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <Typography variant="h4" >House Lambda</Typography>
                </Link>
                </div>
                <button /* navbar-toggler needed when page width shrink and
                        navbar becomes a button + menu, note the data-target
                      */ className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                         aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div /*id = navBarSC enables collapse, but still need to declare each item*/
                    className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <div className="dropdown mr-sm-2">
                            <button className="btn btn-secondary dropdown-toggle bg-light text-primary" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Buy
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item"  to="/buy/buyprocess" >Buy Proces</Link>
                                {/*<a className="dropdown-item" href="/buy/buyprocess">Buy Process</a>*/}
                                <a className="dropdown-item" href="/buy/buyersacademy">Buyer's Academy</a>
                                <a className="dropdown-item" href="/buy/buyersresource">Buyer's Resources</a>
                            </div>
                        </div>
                        <div className="dropdown mr-sm-2">
                            <button className="btn btn-secondary dropdown-toggle bg-light text-primary" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Sell
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item" href="#">Sell Process</a>
                                <a className="dropdown-item" href="#">Seller's Academy</a>
                                <a className="dropdown-item" href="#">Seller's Resources</a>
                            </div>
                        </div>
                        <button className="btn btn-secondary bg-light text-primary border-0" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            1-800-hla-mbda
                        </button>
                    </ul>
                    <form className="form-inline my-2 my-lg-0">
                        <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"/>
                        <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                    </form>
                </div>
            </nav>
        )
    }
}

export default Header;
