import React from 'react';
import {Container, Typography} from "@mui/material";
import ExpandableCard from '../../components/expandableCard/ExpandableCard'
import Link from '@mui/material/Link'
import ReactPlayer from 'react-player'

export default class BuyProcessPage extends React.Component {
    render() {
        return <Container sx={{display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center'}}>
            <Typography variant='h3'>
                Step by Step Home Buying Guide <br/>
            </Typography>
            <Typography variant='body1'>
                Buy Home can be stressful, but we're here to help <br/>
                Do not be afraid to learn here, because it is going to be rewarding
            </Typography>
            <br/>
            <ReactPlayer url='https://www.youtube.com/watch?v=fBP2E8J6SZ0&ab_channel=Marko-WhiteBoardFinance' />
            <br/>
            <ExpandableCard
                cardTitle={"1. Are you ready to own home"}
                cardBody={
                    <Typography>
                        The very first step is know if you're ready to own a home. There're a few basic things you need to think about before diving too deep into the process.

                        Expand this card to see more about some basics.
                    </Typography>
                }
                expandedContent={
                    <>
                        <Typography variant='h5'> Do you have steady income? </Typography>
                        <Typography variant='body1'> Your steady income is a major factor determining how much you could afford, without this your mortgage process would be a bit more difficult<br/></Typography>
                        <Typography variant='h5'> Your debt to income ratio </Typography>
                        <Typography variant='body1'>
                            Debt to income ratio is something mortgage lender would look at, it's calculated by your month debt(credit card + auto loan etc) divided by your monthly gross income.
                            A general rule is this ratio should fall under 0.36 after you buy the house. Lender might be willing to stretch beyond 0.36, but there's some costs involved. <br/> </Typography>
                        <Typography variant='h5'> How is your credit score </Typography>
                        <Typography variant='body1'> Good credit score will lower your mortgage rate, you could sign up for Credit Karma, this app will tell your credit score.<br/></Typography>
                    </>
                }
            />

            <ExpandableCard
                cardTitle={"2. Determine your budget"}
                cardBody={
                    <Typography>
                        Even before you start searching, you should determine how much you can afford with an online tool like <Link
                        href="https://www.nerdwallet.com/mortgages/how-much-house-can-i-afford/calculate-affordability" underline="none" target="_blank" rel="noopener">
                        {'Nerdwallet Home Affordability Calculator'}
                    </Link>. Just plug in some basic information to get an estimate of how much you can spend on a home.
                    </Typography>
                }
            />

            <ExpandableCard
                cardTitle={"3. Down Payment"}
                cardBody={
                    <>
                        <Typography variant='h5'> Save up for a down payment </Typography>
                        <Typography variant='body1'>
                        A big part of your mortgage will be determined based on how much you pay upfront in the form of a <Link
                        href="https://www.nerdwallet.com/mortgages/how-much-house-can-i-afford/calculate-affordability"
                        underline="none" target="_blank" rel="noopener">
                        Down Payment
                        </Link> Generally, the higher the down payment, the better the interest rate will be. If you decide to put down less than 20%, you’ll likely need to pay private mortgage insurance (PMI). Speak with your agent and lender to understand your options and determine the best down payment for you.
                        <br/><br/>
                        <Typography variant='h5'> Don’t forget about closing costs</Typography>
                        Closing costs are fees paid at closing and usually total 2%–5% of the final sale price. Read more about closing costs for buyers below, in Step 6.
                        </Typography>
                    </>
                }
            />

            <ExpandableCard
                cardTitle={"4. Get pre-approved for a mortgage"}
                cardBody={
                    <Typography>
                        Sellers are typically more willing to accept offers from pre-approved buyers, because it shows that the buyer has the financial resources available to make good on their offer.

                        Get quotes from multiple lenders and go with someone reliable. Read online reviews of each lender and consider their responsiveness, transparency, and estimated closing timeline.

                        We suggest you to call up your bank and local credit union, also don't be afraid of online mortgage lender - bankrate.com offers some great options.
                    </Typography>
                }
                expandedContent={
                    <>
                        <Typography variant='h5'> Apply for pre-approval </Typography>
                        <Typography variant='body1'>
                            Once you select a lender, apply for pre-approval. Your lender will check your credit and ask for all of your financial documents—tax returns, pay stubs, bank statements, credit card statements, student and auto loans, etc.—to accurately assess your financial situation.

                            Keep in mind that just because you’re pre-approved for a certain amount doesn’t mean you can actually afford that amount. Prepare your own monthly budget to figure out what you’ll be comfortable paying.
                        </Typography>
                    </>
                }
            />

            <ExpandableCard
                cardTitle={"5. Find A Reliable Real Estate Agent"}
                cardBody={
                    <Typography>
                        There are so many real estate agents in the market, the quality of them varies a lot.
                        To save you time and money, getRebate.house affiliated with top quality agent.
                        You study up and we deliver the last mile.
                        The seller typically pays real estate agent 2 - 3% of the sales price. It's a load of money!
                        And you will get most of them back by studying here and doing you own research.
                    </Typography>
                }
            />

            <ExpandableCard
                cardTitle={"6. Start looking at homes"}
                cardBody={
                    <>
                    <Typography variant='h5'>
                        Once you’re pre-approved and have an agent, you’re ready to look for a home.
                    </Typography>
                    <Typography variant='body2'>
                        If you’re like most homebuyers, you’re browsing homes for sale day and night on Zillow.com/Redfin.com. At this point, it’s a good idea to narrow down your search.

                        Determine your ideal neighborhoods, and make a list of must-haves vs. nice-to-haves. If you’re having a tough time narrowing down, speak with your real estate agent about what she or he thinks is realistic for your price range.
                    </Typography>
                    </>
                }
            />

            <ExpandableCard
                cardTitle={"7. Start looking at homes"}
                cardBody={
                    <>
                        <Typography variant='h5'>
                            Once you’re pre-approved and have an agent, you’re ready to look for a home. <br/><br/>
                            <Typography variant='h5'>Narrow down your search </Typography>
                            <Typography variant='body2'>
                                If you’re like most homebuyers, you’re browsing homes for sale day and night on Zillow.com/Redfin.com. At this point, it’s a good idea to narrow down your search.

                                Determine your ideal neighborhoods, and make a list of must-haves vs. nice-to-haves. If you’re having a tough time narrowing down, speak with your real estate agent about what she or he thinks is realistic for your price range.<br/> <br/>
                            </Typography>
                            <Typography variant='h5'>
                                Tour, tour, tour!
                            </Typography>
                            <Typography variant='body2'>
                                You could go to Redfin open house yourself or talk with our agent to secure a time slot for touring homes.
                            </Typography>
                        </Typography>
                    </>
                }
            />

            <ExpandableCard
                cardTitle={"8. Make Offer"}
                cardBody={
                    <>
                        <Typography variant='h5'>
                            Once you’re pre-approved and have an agent, you’re ready to look for a home. <br/>
                        <Typography variant='h5'>Narrow down your search </Typography>
                        <Typography variant='body2'>
                            If you’re like most homebuyers, you’re browsing homes for sale day and night on Zillow.com/Redfin.com. At this point, it’s a good idea to narrow down your search.

                            Determine your ideal neighborhoods, and make a list of must-haves vs. nice-to-haves. If you’re having a tough time narrowing down, speak with your real estate agent about what she or he thinks is realistic for your price range.<br/> <br/>
                        </Typography>
                        <Typography variant='h5'>
                            Tour, tour, tour!
                        </Typography>
                        <Typography variant='body2'>
                            You could go to Redfin open house yourself or talk with our agent to secure a time slot for touring homes.
                        </Typography>
                        </Typography>
                    </>
                }
            />

        </Container>
    }
}