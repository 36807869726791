import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


export default class ExpandableCard extends React.Component {
    constructor() {
        super();
        this.state = {
            expanded: false,
        }
    }

    handleExpandClick = () => {
        this.setState({
            ...this.state,
            expanded: !this.state.expanded
        });
    };

    render() {
        return (
            <Card sx={{ maxWidth: 900 }}>
                <CardHeader
                    title={this.props.cardTitle}
                />
                <CardMedia
                    component="img"
                    image={this.props.imageUrl}
                    alt=""
                />
                <CardContent>
                    <Typography variant="body1" color="text.secondary">
                        {this.props.cardBody}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <ExpandMore
                        expand={this.state.expanded}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="show more"
                    >
                        {this.props.expandedContent? <ExpandMoreIcon />: undefined}
                    </ExpandMore>
                </CardActions>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography variant="body1" color="text.secondary">
                        {this.props.expandedContent}
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        );
    }
}
