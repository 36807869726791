import React from 'react';
import {Container, Typography} from "@mui/material";
import axios from 'axios';
import ReactMarkdown from 'react-markdown'

export default class BuyersAcademyPage extends React.Component {
    constructor() {
        super();
        this.state = {
            pageData : undefined
        }
    }

    componentDidMount() {
        axios.get('https://murmuring-shore-91579.herokuapp.com/articles').then(response => {
            this.setState({pageData: response.data[0]});
        });
    }

    render() {
        return <Container sx={{display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center'}}>
            <ReactMarkdown children={this.state.pageData?this.state.pageData.content: ''} />
        </Container>
    }
}