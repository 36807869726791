import React from 'react';
import './Home.css';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const DEFAULT_HOME_VALUE = 600000;
class Home extends React.Component {
    constructor() {
        super();
        this.onShowSavingButtonClicked = this.onShowSavingButtonClicked.bind(this);
        this.propertyValueInputRef = this.myRef = React.createRef();
        this.state = {
            propertyValueIntValue: null
        }
    }

    onShowSavingButtonClicked = () => {
        this.setState({
            propertyValueIntValue: Number.parseInt(this.propertyValueInputRef.current.value)
        });
    }

    getPropertyValueOrDefault = () => {
        return (this.propertyValueInputRef.current ?
            this.propertyValueInputRef.current.value: DEFAULT_HOME_VALUE);
    }

    basicCard = (renderText, moneySaved, secondaryText, learnMoreUrl) => {
        return (
            <Card sx={{}}>
                <CardContent>
                    <Typography variant="h5" component="div" sx={{color: 'blue'}}>
                        {renderText}
                    </Typography>
                    <Typography variant="h6">
                        You will save up to <Typography variant="span" sx={{color: 'blue'}}>${moneySaved}</Typography>
                    </Typography>
                    <Typography variant="body1">
                        {secondaryText}
                    </Typography>
                </CardContent>
                {learnMoreUrl.length?
                <CardActions>
                    <Link href={learnMoreUrl} underline="none" target="_blank" rel="noopener">
                        Learn More
                    </Link>
                </CardActions>: null}
            </Card>
        );
    }

    render() {
        return (
            <div className='home'>
                <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="img-fluid w-100" src="https://getrebate.s3.us-west-2.amazonaws.com/Home_intro_background.jpg" alt="" style={{height: 686, "object-fit": "cover"}}/>
                            <div className='home__savings_wrapper'>
                                <h2 className='home__intro__text'>
                                    Wonder how much you could save?
                                </h2>
                                <div className='home_savings_button_box'>
                                    <input ref={this.propertyValueInputRef} className="form-control mr-sm-1" type="text" placeholder="Your Property Price" aria-label="propertyPrice"/>
                                    <button className="w-50 btn btn-outline-success my-2 my-sm-0" type="submit" onClick={this.onShowSavingButtonClicked}>Check Savings</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Box sx={{padding: '20px'}}/>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <Typography variant='h3'>
                        Largest Buyer Rebates & Lowest Selling Fees
                    </Typography>
                    <Typography variant='body1'>
                        If you think real estate agents are paid too much of YOUR money, you are not alone. <br/>
                        Everyone knows technology has made buying and selling a home less expensive for agents, we pass the savings onto you!
                    </Typography>

                </div>

                <Box sx={{padding: '20px'}}/>

                <Box sx={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                    <h1>
                    See how much House Lambda saves you?
                    </h1>
                </Box>
                <br/>
                <Grid container spacing={3}>
                    <Grid item xs>
                        {this.basicCard('Buy with Redfin.com',
                            this.getPropertyValueOrDefault() * 0.01,
                            'You have to buy and sell within a year',
                            'https://www.redfin.com/why-redfin-how-you-save'
                        )}
                    </Grid>
                    <Grid item xs>
                        {this.basicCard('Buy with traditional agent',
                            this.getPropertyValueOrDefault() * 0.01,
                            'You mileage may vary, most experienced estate agents do not give rebate',
                            '') // do not show learn more
                        }
                    </Grid>
                    <Grid item xs = {6}>
                        {this.basicCard('Buy with House Lambda',
                            this.getPropertyValueOrDefault() * 0.02,
                            'You will not only get rebate, but get the same quality agent from big name broker',
                            "someDummyUrl"
                        )}
                    </Grid>
                </Grid>
            <div className='home__section'>
                {/*<Card*/}
                {/*    src="https://a0.muscache.com/im/pictures/eb9c7c6a-ee33-414a-b1ba-14e8860d59b3.jpg?im_w=720"*/}
                {/*    title="Online Experiences"*/}
                {/*    description="Unique activities we can do together, led by a world of hosts."*/}
                {/*/>*/}
                {/*<Card*/}
                {/*    src="https://a0.muscache.com/im/pictures/15159c9c-9cf1-400e-b809-4e13f286fa38.jpg?im_w=720"*/}
                {/*    title="Unique stays"*/}
                {/*    description="Spaces that are more than just a place to sleep."*/}
                {/*/>*/}
                {/*<Card*/}
                {/*    src="https://a0.muscache.com/im/pictures/fdb46962-10c1-45fc-a228-d0b055411448.jpg?im_w=720"*/}
                {/*    title="Entire homes"*/}
                {/*    description="Comfortable private places, with room for friends or family."*/}
                {/*/>*/}
                {/*</div>*/}
                {/*<div className='home__section'>*/}
                {/*<Card*/}
                {/*    src="https://media.nomadicmatt.com/2019/airbnb_breakup3.jpg"*/}
                {/*    title="3 Bedroom Flat in Bournemouth"*/}
                {/*    description="Superhost with a stunning view of the beachside in Sunny Bournemouth"*/}
                {/*    price="£130/night"*/}
                {/*/>*/}
                {/*<Card*/}
                {/*    src="https://thespaces.com/wp-content/uploads/2017/08/Courtesy-of-Airbnb.jpg"*/}
                {/*    title="Penthouse in London"*/}
                {/*    description="Enjoy the amazing sights of London with this stunning penthouse"*/}
                {/*    price="£350/night"*/}
                {/*/>*/}
                {/*<Card*/}
                {/*    src="https://media.nomadicmatt.com/2018/apartment.jpg"*/}
                {/*    title="1 Bedroom apartment"*/}
                {/*    description="Superhost with great amenities and a fabolous shopping complex nearby"*/}
                {/*    price="£70/night"*/}
                {/*/>*/}
            </div>
        </div>)
    }
}

export default Home
